<template>
  <div>
    <Header/>

    <section id="order">
      <div class="inner w1300">
        <div class="breadcrumbs">
          <span class="txt-lightgrey">
            <a href="member_order.php">
              訂單查詢
            </a> > 訂單編號
            <template v-if="orders.length > 0">
              {{orders[0].order_no}}
            </template>
          </span> > 填寫滿意度問卷
        </div>

        <form>
          <div class="order_area">

            <div class="area_title">
              <img src="@/assets/img/account/icon_title_smile.svg" alt="">
              <div>
                <h4 class="txt-bold">填寫滿意度問卷</h4>
                <p v-if="orders.length > 0">訂單編號 {{orders[0].order_no}}</p>
              </div>
            </div>

            <div class="area_questionnaire">

              <!-- eslint-disable vue/no-use-v-if-with-v-for -->
              <template
                v-for="(order, key) in orders"
                v-if="order">
                  <!-- 每種產品的title顏色不一樣 class要加上 wifi/sim/esim/product -->
                  <div
                    :key="`top${key}`"
                    class="questionnaire_title wifi">
                      <h6 class="txt-white txt-bold">商品 {{key+1}}</h6>
                  </div>
                  <div
                    :key="`body${key}`"
                    class="questionnaire_box">
                      <div class="questionnaire_product">
                        <div class="picbox">
                          <figure
                            class="cart_pic"
                            :style="`background-image: url(${order.pic});`">
                          </figure>
                        </div>
                        <div class="name">
                          <div class="tag small">{{order.category}}</div>
                          <h6 class="txt-bold">{{order.name}}</h6>
                          <small class="txt-greenlake">{{order.note}}</small>
                        </div>
                      </div>

                      <RateItem
                        v-for="(item, i) in order.ans"
                        :key="`RateItem${i}`"
                        :set="item"
                      />

                      <div class="questionnaire_item">
                        <h6>整體滿意度</h6>
                        <div class="ratebox">
                          <Star
                            v-for="n in 5"
                            :key="n"
                            :class="{ active: order.star >= n }"
                            @click.native="order.star = n"/>
                        </div>
                      </div>

                      <div class="questionnaire_item">
                        <textarea
                          cols="30"
                          rows="4"
                          placeholder="請輸入您的心得與建議"
                          :readonly="order.ifFeedbackReadonly || ifWrote"
                          :class="{ readonly: order.ifFeedbackReadonly || ifWrote }"
                          v-model="order.feedback">
                        </textarea>
                      </div>
                  </div>

                  <!-- <div class="suggest_box">
                    <h6 class="txt-bold">使用心得與建議</h6>
                    <textarea cols="30" rows="10" placeholder="請輸入文字 (必填)"></textarea>
                  </div> -->
              </template>

            </div>

          </div>

          <div class="btnset">
            <router-link
              :to="{ name: 'MemberOrder' }"
              class="btn btn-grey">
                <p>返回</p>
            </router-link>
            <button
              type="button"
              v-if="!ifWrote"
              @click="sendOrder();"
              class="btn btn-blue_gradient">
                <p>送出</p>
            </button>
          </div>
        </form>

      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import Header from '@/components/Header.vue';
import RateItem from '@/components/orderQuestionnaire/OrderQuestionnaireRateItem.vue';
import Star from '@/components/orderQuestionnaire/OrderQuestionnaireStar.vue';
import { required } from '@/consts/validator';
import requestApi from '@/lib/http';
import '@/assets/scss/questionnaire.scss';

export default {
  name: 'OrderQuestionnaire',
  props: ['id'],
  components: {
    Header,
    RateItem,
    Star,
  },
  data() {
    return {
      ifWrote: false,
      orders: [],
    };
  },
  computed: {
    ...mapState([
      'user',
    ]),
  },
  methods: {
    async getOrder() {
      this.orders = [];
      const orders = [];
      const { data } = await requestApi('member.getQuestionnaire', {
        order_id: this.id || this.$route.params.id,
      });
      if (data) {
        data.forEach((element) => {
          element.items.forEach((elem) => {
            if (elem.star) {
              this.ifWrote = true;
            }
          });
          orders.push({
            detail_id: element.detail_id,
            product_id: element.product_id,
            questionnaire_id: element.questionnaire_id,
            type_id: element.type_id,
            pic: element.cover,
            category: element.category,
            name: element.product_name,
            order_no: element.order_no,
            // note: '流量: 吃到飽',
            feedback: element.reason || '',
            ifFeedbackReadonly: Boolean(element.reason),
            star: element.star,
            ans: (() => {
              const ansTemp = [];
              element.items.forEach((elem) => {
                ansTemp.push({
                  id: elem.id,
                  name: elem.title,
                  value: elem.star,
                  reason: elem.reason,
                  readonly: Boolean(elem.star),
                });
              });
              return ansTemp;
            })(),
          });
        });
      } else {
        this.ifWrote = true;
      }

      this.orders = [...orders];
    },
    async sendOrder() {
      const sendData = [];
      let flag = true;

      this.orders.forEach((element) => {
        element.ans.forEach((elem) => {
          if (!elem.value) {
            flag = false;
            this.$customSWAL({
              icon: 'error',
              title: '請評分全部項目',
            });
            return false;
          }
          if (elem.value <= 2 && !required(elem.reason)) {
            flag = false;
            this.$customSWAL({
              icon: 'error',
              title: `請填寫${elem.name}原因`,
            });
            return false;
          }
          return false;
        });
      });

      if (flag) {
        this.orders.forEach((element) => {
          sendData.push({
            order_id: this.id || this.$route.params.id,
            detail_id: element.detail_id,
            member_id: this.user.id,
            product_id: element.product_id,
            type_id: element.type_id,
            questionnaire_id: element.questionnaire_id,
            reason: element.feedback,
            // star: (() => {
            //   let starTemp = 0;
            //   element.ans.forEach((elem) => {
            //     starTemp += elem.value;
            //   });
            //   return starTemp / element.ans.length;
            // })(),
            star: element.star,
            items: (() => {
              const ansTemp = [];
              element.ans.forEach((elem) => {
                ansTemp.push({
                  subject_id: elem.id,
                  star: elem.value,
                  reason: elem.reason ? elem.reason : null,
                });
              });
              return ansTemp;
            })(),
          });
        });

        const { status, message } = await requestApi('member.recordQuestionnaire', { questionnaires: sendData });

        if (status) {
          this.$customSWAL({
            title: message,
            thenFunc: () => {
              this.$router.push({ name: 'MemberOrder' });
            },
          });
        } else {
          this.$customSWAL({
            icon: 'error',
            title: message,
          });
        }
      }
    },
  },
  created() {
    this.getOrder();
  },
};
</script>
